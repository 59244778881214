/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: LinkrFont-Regular;
  src: url("./fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: LinkrFont-Bold;
  src: url("./fonts/Lato/Lato-Bold.ttf");
}

.page.admin {
  margin: 0px 0;
  font-family: LinkrFont-Regular;
}

.wrapper.admin {
  max-width: 100wh;
  margin: 0 auto;
  position: relative;
  padding: 0 0px;
  min-height: 100vh;
}

.content-wrapper.admin {
  float: right;
  width: 100%;
}

.content.admin {
  height: calc(100vh - 30px);
  margin-left: 240px;
  margin-top: 10px;
  clear: both;
  overflow: auto;
  background: white;
  min-height: calc(100vh - 30px);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.content-centered.admin {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.content.admin.mobile {
  margin-left: 40px;
}

.footer.admin {
  min-height: 30px !important;
  max-height: 30px !important;
  margin-left: 230px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 230px);
  text-align: center;
  overflow: hidden;
  background-color: whitesmoke;
}

.footer.admin.mobile {
  margin-left: 30px;
  width: calc(100% - 30px);
}

.sidebar.admin {
  background: #439afe;
  background: -webkit-linear-gradient(0deg, #439afe 0%, #439afe 100%);
  background: -o-linear-gradient(0deg, #439afe 0%, #439afe 100%);
  background: -moz-linear-gradient(0deg, #439afe 0%, #439afe 100%);
  background: linear-gradient(0deg, #439afe 0%, #3171b9 100%);
  position: relative;
  width: 230px;
  margin-right: -230px;
  float: right;
  overflow: hidden;
  color: whitesmoke;
  min-height: 100vh;
}

.sidebar.admin.mobile {
  width: 30px;
  margin-right: -30px;
}

.sidebar-hidearea.admin {
  max-height: 30px !important;
  min-height: 30px !important;
  float: right;
  margin-right: 6px;
}

.sidebar-content.admin {
  max-height: calc(100vh - 30px) !important;
  overflow: auto;
}

.sidebar-content.admin.mobile {
  display: none;
}

.sidebar-toggle-icon.admin {
  size: 30px;
  color: white;
}

.sidebar-toggle-icon.admin:hover {
  cursor: pointer;
}

.admin-sidebar-link-first-container {
  color: whitesmoke;
  height: auto;
  min-width: 180px;
  max-width: 180px;
  margin-top: 30px;
  margin-left: 1rem;
  margin-bottom: 60px;
  text-align: center;
  border-bottom: solid;
  border-width: 1px;
  border-color: #a8d1ff;
}

.admin-sidebar-link-first-container :hover {
  color: whitesmoke;
}

.admin-sidebar-link-first-icon {
  width: 48px !important;
  height: 48px !important;
  text-align: center;
  color: whitesmoke;
  margin: auto;
  margin-bottom: 35px;
  color: whitesmoke;
}

.admin-sidebar-link-first-title {
  margin: auto;
  text-align: center;
  font-size: 14px;
  margin-bottom: 1px;
  color: whitesmoke;
}

.admin-sidebar-link-first-subtitle {
  margin: auto;
  text-align: center;
  font-size: 16px;
  font-family: LinkrFont-Bold;
  margin-bottom: 5px;
  color: whitesmoke;
}

.admin-sidebar-link-container {
  height: 50px;
  min-width: 180px;
  max-width: 180px;
  color: whitesmoke;
  text-align: center;
  margin-left: 12px;
  border-bottom: solid;
  border-width: 1px;
  border-color: #a8d1ff;
  font-size: 14px;
}

.admin-sidebar-link-container:hover {
  border-left: solid;
  border-left-width: 8px;
  border-left-color: #a8d1ff;
  color: whitesmoke;
}

.admin-sidebar-link-icon-container {
  width: 38px;
  position: relative;
}

.admin-sidebar-link-icon {
  width: 20px !important;
  height: 20px !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.admin-sidebar-link-text-container {
  min-width: 140px;
  max-width: 140px;
  height: 38px;
  text-align: center;
  margin: 0 auto;
}

.admin-sidebar-link-text {
  margin: 0;
  position: absolute;
  color: whitesmoke;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.admin-sidebar-link-last-container {
  height: 50px;
  min-width: 180px;
  max-width: 180px;
  text-align: center;
  color: whitesmoke;
  margin-left: 12px;
  border-top: solid;
  border-width: 1px;
  border-color: #a8d1ff;
  font-size: 14px;
  position: absolute;
  bottom: 30px;
}

.admin-sidebar-link-last-container :hover {
  border-left: solid;
  border-left-width: 8px;
  border-left-color: #a8d1ff;
  color: whitesmoke;
}

.admin-card {
  width: 14rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  margin: 10px;
}

.admin-card-title {
  text-align: center;
  font-size: 18px;
}

.admin-card-text {
  text-align: center;
  font-size: 28px;
  color: white;
}

.admin-card-icon-row-container {
  margin-top: 28px;
  margin-bottom: 28px;
}

.admin-card-icon-col-container {
  width: 42px;
  position: relative;
}

.admin-card-icon {
  width: 36px !important;
  height: 36px !important;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.admin-chart {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  margin: 10px;
}

.admin-chart-tooltip {
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border: 2px solid #c3c3c3;
  border-radius: 10px;
  font-size: 8px;
  color: black;
}

.admin-chart-legend-p {
  text-align: center;
  font-size: 16px;
}

.admin-footer-container {
  min-height: 30px !important;
  max-height: 30px !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  overflow: hidden;
  background-color: whitesmoke;
}

.admin-header-container {
  position: relative;
  top: 0px;
  height: 60px !important;
  width: 100%;
  border-bottom: solid;
  border-bottom-width: 1px;
  border-bottom-color: #3171b9;
  margin: 0 auto;
}

.admin-header-text {
  margin: 0;
  font-family: LinkrFont-Bold;
  position: absolute;
  color: #3171b9;
  font-size: 18px;
  height: 30px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.admin-header-search {
  margin: 0;
  font-family: LinkrFont-Bold;
  position: absolute;
  color: #3171b9;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.admin-feat-default {
  border: 1px solid #3171b9;
  color: #3171b9;
  background-color: rgba(49, 113, 185, 0.1);
  font-size: "12px";
  cursor: "pointer";
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-feat-selected {
  border: 1px solid purple;
  color: purple;
  background-color: rgb(128, 0, 128, 0.1);
  font-size: "12px";
  cursor: "pointer";
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-feat-selected-default {
  border: 1px solid #3171b9;
  color: #3171b9;
  background-color: rgba(49, 113, 185, 0.1);
  font-size: "12px";
  cursor: "pointer";
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-feat-normal {
  border: 1px solid black;
  color: black;
  font-size: "12px";
  cursor: "pointer";
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.admin-feat-title {
  color: black;
  font-size: "12px";
  cursor: "pointer";
  border: 1px solid #3171b9;
  color: white;
  background-color: #3171b9;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
}

.admin-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: LinkrFont-Regular;
  src: url("./fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: LinkrFont-Bold;
  src: url("./fonts/Lato/Lato-Bold.ttf");
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* ------------------------------------ */
a {
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

.color-linkr1 {
  color: #3171b9ff !important;
}

.theme-linkr1 {
  background-color: #3171b910 !important;
  color: #3171b9ff !important;
}

.color-linkr2 {
  color: rgba(128, 0, 128, 1) !important;
}

.color-gray {
  color: gray !important;
}

.theme-linkr2 {
  background-color: rgba(128, 0, 128, 0.1) !important;
  color: rgba(128, 0, 128, 1) !important;
}

.color-linkr3 {
  color: rgba(0, 0, 0, 1) !important;
}
.theme-linkr3 {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 1) !important;
}
.color-linkr4 {
  color: #3171b9ff !important;
}

.theme-linkr4 {
  background-color: #3171b9ff !important;
  color: white !important;
  border-color: #3171b9ff !important;
}

.theme-linkr5 {
  background-color: #439afe !important;
  color: white !important;
  border-color: #439afe !important;
}

.theme-linkr6 {
  background-color: #3171b910 !important;
  color: #3171b9ff !important;
}

/* ------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/* ------------------------------------ */
input {
  display: block;
  outline: none;
}

textarea {
  display: block;
  outline: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

/* ------------------------------------ */
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
  [ Scroll bar ]*/
.js-pscroll {
  position: relative;
  overflow: hidden;
}

.table100 .ps__rail-y {
  width: 9px;
  background-color: transparent;
  opacity: 1 !important;
  right: 5px;
}

.table100 .ps__rail-y::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #ebebeb;
  border-radius: 5px;
  width: 100%;
  height: calc(100% - 30px);
  left: 0;
  top: 15px;
}

.table100 .ps__rail-y .ps__thumb-y {
  width: 100%;
  right: 0;
  background-color: transparent;
  opacity: 1 !important;
}

.table100 .ps__rail-y .ps__thumb-y::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #cccccc;
  border-radius: 5px;
  width: 100%;
  height: calc(100% - 30px);
  left: 0;
  top: 15px;
}

/*//////////////////////////////////////////////////////////////////
  [ Table ]*/

.limiter {
  width: 1570px;
  margin: 0 auto;
}

.container-table100 {
  width: 100%;
  min-height: 100vh;
  background: #fff;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}

.wrap-table100 {
  width: 1270px;
}

/*//////////////////////////////////////////////////////////////////
  [ Table ]*/
.table100 {
  background-color: #fff;
  width: 100%;
}

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
}

/* Firmware Table */

.column0.firmware {
  width: 4%;
  text-align: center;
}
.column1.firmware {
  width: 14%;
  text-align: center;
}
.column2.firmware {
  width: 14%;
  text-align: center;
}
.column3.firmware {
  width: 12%;
  text-align: center;
}
.column4.firmware {
  width: 18%;
  text-align: center;
}
.column5.firmware {
  width: 18%;
  text-align: center;
}
.column6.firmware {
  width: 10%;
  text-align: center;
}
.column7.firmware {
  width: 11%;
  text-align: center;
}

/* 4*12 + 1*4 + 2*26*/

/* User Table */

/*#*/
.column0.user {
  width: 4%;
  text-align: center;
}
/*Email*/
.column1.user {
  width: 18%;
  text-align: center;
}
/*Name*/
.column2.user {
  width: 10%;
  text-align: center;
}
/*Role*/
.column3.user {
  width: 12%;
  text-align: center;
}
/*Owned*/
.column4.user {
  width: 12%;
  text-align: center;
}
/*Authorised*/
.column5.user {
  width: 12%;
  text-align: center;
}
/*Firmware access*/
.column6.user {
  width: 10%;
  text-align: center;
}





/* Device Table */

.column0.device {
  width: 4%;
  text-align: center;
}
.column1.device {
  width: 20%;
  text-align: center;
}
.column2.device {
  width: 20%;
  text-align: center;
}
.column3.device {
  width: 20%;
  text-align: center;
}
.column4.device {
  width: 20%;
  text-align: center;
}
.column5.device {
  width: 6%;
  text-align: center;
}
.column6.device {
  width: 6%;
  text-align: center;
}
.column7.device {
  width: 6%;
  text-align: center;
}


.column0.search {
  width: 4%;
  text-align: center;
}
.column1.search {
  width: 48%;
  text-align: center;
}

.column0.brand {
  width: 4%;
  text-align: center;
}
.column1.brand {
  width: 24%;
  text-align: center;
}

/*#*/
.column0.products {
  width: 4%;
  text-align: center;
}
/*Create date*/
.column1.products {
  width: 15%;
  text-align: center;
}
/*Create by*/
.column2.products {
  width: 15%;
  text-align: center;
}
/*Product name*/
.column3.products {
  width: 15%;
  text-align: center;
}
.column4.products {
  width: 15%;
  text-align: center;
}
.column5.products {
  width: 15%;
  text-align: center;
}
.column6.products {
  width: 10%;
  text-align: center;
}
.column7.products {
  width: 13%;
  text-align: center;
}




.column0.uld {
  width: 25%;
  text-align: center;
}

.column1.uld {
  width: 8%;
  text-align: center;
}

.column-full {
  width: 100%;
  text-align: center;
}

.column0 {
  width: 2%;
  text-align: center;
}

.column1 {
  width: 33%;
  text-align: center;
}

.column2 {
  width: 11%;
  text-align: center;
}

.column3 {
  width: 22%;
  text-align: center;
}

.column4 {
  width: 19%;
  text-align: center;
}

.column5 {
  width: 13%;
  text-align: center;
}

.column6 {
  width: 4%;
  text-align: center;
}

.table100-head th {
  padding-top: 18px;
  padding-bottom: 18px;
}

.table100-body td {
  padding-top: 10px;
  padding-bottom: 10px;
}

/*==================================================================
  [ Fix header ]*/
.table100 {
  position: relative;
  padding-top: 60px;
}

.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.table100-body {
  max-height: calc(100vh - 290px);
  overflow: auto;
}

.table100-body.full {
  max-height: calc(100vh - 250px);
  overflow: auto;
}

/*==================================================================
  [ Ver1 ]*/

.table100.ver1 th {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: #3171b9ff;
  line-height: 0.8;
  text-transform: uppercase;
  background-color: transparent;
}

.table100.ver1 td {
  font-family: LinkrFont-Bold;
  font-size: 10px;
  color: #3171b9ff;
  text-transform: uppercase;
  line-height: 0.8;
}

.table100.ver1 .table100-body tr:nth-child(even) {
  background-color: #3171b910;
}

/*---------------------------------------------*/

.table100.ver1 {
  border-radius: 3px;
  overflow: hidden;
}

.table100.ver1 .ps__rail-y {
  right: 5px;
}

.table100.ver1 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver1 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

.table100.ver1 tr:hover td {
  background-color: #7eb8fb;
  color: white;
  cursor: pointer;
}

.table100.ver1 #action {
  opacity: 0;
}

.table100.ver1 tr:hover #action {
  opacity: 1;
}

.table100.ver1 #link {
  text-decoration: none;
}

.table100.ver1 tr:hover #link {
  text-decoration: underline;
}

/*==================================================================
  [ Ver2 ]*/

.table100.ver2 .table100-head {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
}

.table100.ver2 th {
  font-family: LinkrFont-Bold;
  font-size: 18px;
  color: #fa4251;
  line-height: 1.4;

  background-color: transparent;
}

.table100.ver2 td {
  font-family: LinkrFont-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.4;
}

.table100.ver2 .table100-body tr {
  border-bottom: 1px solid #f2f2f2;
}

/*---------------------------------------------*/

.table100.ver2 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.table100.ver2 .ps__rail-y {
  right: 5px;
}

.table100.ver2 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver2 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

/*==================================================================
  [ Ver3 ]*/

.table100.ver3 {
  background-color: #393939;
}

.table100.ver3 th {
  font-family: LinkrFont-Bold;
  font-size: 15px;
  color: #00ad5f;
  line-height: 1.4;
  text-transform: uppercase;
  background-color: #393939;
}

.table100.ver3 td {
  font-family: LinkrFont-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.4;
  background-color: #222222;
}

/*---------------------------------------------*/

.table100.ver3 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.table100.ver3 .ps__rail-y {
  right: 5px;
}

.table100.ver3 .ps__rail-y::before {
  background-color: #4e4e4e;
}

.table100.ver3 .ps__rail-y .ps__thumb-y::before {
  background-color: #00ad5f;
}

/*==================================================================
  [ Ver4 ]*/
.table100.ver4 {
  margin-right: 0px;
}

.table100.ver4 .table100-head {
  padding-right: 0px;
}

.table100.ver4 .table100-body {
  padding-right: 0px;
}

.table100.ver4 tr:nth-child(even) {
  background: #ecf3fa;
}

.table100.ver4 th {
  font-family: LinkrFont-Bold;
  font-size: 16px;
  color: white;
  line-height: 1.8;

  background-color: #3171b9;
  border-bottom: 2px solid #f2f2f2;
}

.table100.ver4 td {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: #808080;
  line-height: 0.8;
}

.table100.ver4 p {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: #808080;
}

.table100.ver4 tr:hover td {
  background-color: #7eb8fb;
  color: white;
  cursor: pointer;
}

.table100.ver4 tr:hover p {
  background-color: #7eb8fb;
  color: white;
  cursor: pointer;
}

.table100.ver4 .table100-body tr {
  border-bottom: 1px solid #f2f2f2;
}

/*---------------------------------------------*/

.table100.ver4 a {
  opacity: 0;
}

.table100.ver4 tr:hover a {
  opacity: 1;
}

.table100.ver4 .ps__rail-y {
  right: 0px;
}

.table100.ver4 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver4 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

/*==================================================================
  [ Ver5 ]*/
.table100.ver5 {
  margin-right: -30px;
}

.table100.ver5 .table100-head {
  padding-right: 30px;
}

.table100.ver5 th {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: #555555;
  line-height: 1.4;
  text-transform: uppercase;

  background-color: transparent;
}

.table100.ver5 td {
  font-family: LinkrFont-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.4;

  background-color: #f7f7f7;
}

.table100.ver5 .table100-body tr {
  overflow: hidden;
  border-bottom: 10px solid #fff;
  border-radius: 10px;
}

.table100.ver5 .table100-body table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table100.ver5 .table100-body td {
  border: solid 1px transparent;
  border-style: solid none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table100.ver5 .table100-body td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table100.ver5 .table100-body td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.table100.ver5 tr:hover td {
  background-color: #ebebeb;
  cursor: pointer;
}

.table100.ver5 .table100-head th {
  padding-top: 25px;
  padding-bottom: 25px;
}

/*---------------------------------------------*/

.table100.ver5 {
  overflow: hidden;
}

.table100.ver5 .table100-body {
  padding-right: 30px;
}

.table100.ver5 .ps__rail-y {
  right: 0px;
}

.table100.ver5 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver5 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

/*==================================================================
  [ Ver6 ]*/
.table100.ver6 {
  margin-right: -30px;
}

.table100.ver6 .table100-head {
  padding-right: 30px;
}

.table100.ver6 th {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: #555555;
  line-height: 1.4;
  text-transform: uppercase;

  background-color: transparent;
}

.table100.ver6 td {
  font-family: LinkrFont-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.4;
  background-color: #f7f7f7;
}

.table100.ver6 .table100-body tr {
  overflow: hidden;
  border-bottom: 10px solid #fff;
  border-radius: 10px;
}

.table100.ver6 .table100-body table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table100.ver6 .table100-body td {
  border: solid 1px transparent;
  border-style: solid none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table100.ver6 .table100-body td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table100.ver6 .table100-body td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

.table100.ver6 tr:hover td {
  background-color: #ebebeb;
}

.table100.ver6 .table100-head th {
  padding-top: 25px;
  padding-bottom: 25px;
}

.table100.ver6 #action {
  opacity: 0;
}

.table100.ver6 tr:hover #action {
  opacity: 1;
}

/*---------------------------------------------*/

.table100.ver6 {
  overflow: hidden;
}

.table100.ver6 .table100-body {
  padding-right: 30px;
}

.table100.ver6 .ps__rail-y {
  right: 0px;
}

.table100.ver6 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver6 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}
/*==================================================================
  [ Ver7 ]*/

.table100.ver7 {
  margin-right: -10px;
}

.table100.ver7 .table100-head {
  padding-right: 30px;
}

.table100.ver7 th {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: #555555;
  line-height: 1.4;
  text-transform: uppercase;

  background-color: transparent;
}

.table100.ver7 td {
  font-family: LinkrFont-Regular;
  font-size: 10px;
  color: #808080;
  line-height: 1;
  text-transform: uppercase;
  background-color: #f7f7f7;
  min-width: 120px;
}

.table100.ver7 .table100-body tr {
  overflow: hidden;
  border-bottom: 10px solid #fff;
  border-radius: 10px;
}

.table100.ver7 .table100-body table {
  border-collapse: separate;
  border-spacing: 0 1px;
}
.table100.ver7 .table100-body td {
  border: solid 1px transparent;
  border-style: solid none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.table100.ver7 .table100-body td:first-child {
  border-left-style: solid;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}
.table100.ver7 .table100-body td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

.table100.ver7 tr:hover td {
  background-color: #ebebeb;
}

.table100.ver7 .table100-head th {
  padding-top: 25px;
  padding-bottom: 25px;
}

.table100.ver7 #action {
  opacity: 0;
}

.table100.ver7 tr:hover #action {
  opacity: 1;
}

/*---------------------------------------------*/

.table100.ver7 {
  overflow: hidden;
}
.table100.ver7 .table100-body {
  padding-right: 30px;
}
.table100.ver7 .ps__rail-y {
  right: 0px;
}
.table100.ver7 .ps__rail-y::before {
  background-color: #ebebeb;
}
.table100.ver7 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

/*==================================================================
  [ Ver8 ]*/
.table100.ver8 {
  margin-right: -20px;
}

.table100.ver8 .table100-head {
  padding-right: 20px;
}

.table100.ver8 th {
  font-family: LinkrFont-Bold;
  font-size: 14px;
  color: white;
  line-height: 0.1;

  background-color: #3171b9;
  border-bottom: 2px solid #f2f2f2;
}

.table100.ver8 td {
  font-family: LinkrFont-Regular;
  font-size: 14px;
  color: #808080;
  line-height: 0.1;
}

.table100.ver8 .table100-body tr {
  border-bottom: 1px solid #f2f2f2;
}

/*---------------------------------------------*/

.table100.ver8 {
  overflow: hidden;
}

.table100.ver8 a {
  opacity: 0;
}

.table100.ver8 tr:hover a {
  opacity: 1;
}

.table100.ver8 .table100-body {
  padding-right: 20px;
}

.table100.ver8 .ps__rail-y {
  right: 0px;
}

.table100.ver8 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver8 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: LinkrFont-Regular;
  src: url("./fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: LinkrFont-Bold;
  src: url("./fonts/Lato/Lato-Bold.ttf");
}

.page.auth {
  margin: 0px 0;
  font-family: LinkrFont-Regular;
}

.container-fluid.auth {
  font-family: LinkrFont-Regular;
  min-height: calc(100vh - 40px);

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: whitesmoke;
}

.footer.auth {
  margin: auto;
  width: auto;
  min-height: 40px;
  max-height: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: whitesmoke;
}

.container-form-btn-admin {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.black-form-btn-admin {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: black;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.black-form-btn-admin:hover {
  background: #333333;
}

.blue-form-btn-admin {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #439afe;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.blue-form-btn-admin:hover {
  background: #333333;
}

.purple-form-btn-admin {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: purple;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.purple-form-btn-admin:hover {
  background: #333333;
}

.red-form-btn-admin {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: red;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.red-form-btn-admin:hover {
  background: #333333;
}

.green-form-btn-admin {
  font-family: Montserrat-Bold;
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: green;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.green-form-btn-admin:hover {
  background: #333333;
}

.green-form-btn-admin-small {
  font-family: Montserrat-Bold;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 60%;
  height: 25px;
  border-radius: 25px;
  background: green;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.green-form-btn-admin-small:hover {
  background: #333333;
}

.red-form-btn-admin-small {
  font-family: Montserrat-Bold;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 60%;
  height: 25px;
  border-radius: 25px;
  background: red;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.red-form-btn-admin-small:hover {
  background: #333333;
}

.purple-form-btn-admin-small {
  font-family: Montserrat-Bold;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 60%;
  height: 25px;
  border-radius: 25px;
  background: purple;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.purple-form-btn-admin-small:hover {
  background: #333333;
}

.blue-form-btn-admin-small {
  font-family: Montserrat-Bold;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 60%;
  height: 25px;
  border-radius: 25px;
  background: #439afe;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.blue-form-btn-admin-small:hover {
  background: #333333;
}

.blue-form-btn-admin-verysmall {
  font-family: Montserrat-Bold;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 20%;
  height: 45px;
  border-radius: 25px;
  background: #439afe;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.blue-form-btn-admin-verysmall:hover {
  background: #333333;
}

.red-form-btn-admin-verysmall {
  font-family: Montserrat-Bold;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 35%;
  height: 20px;
  border-radius: 25px;
  background: red;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.blue-form-btn-admin-verysmall:hover {
  background: #333333;
}
